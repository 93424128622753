import React, { useState, useEffect } from "react";
import TableCard from "../../../components/Card/TableCard";
import StatsCard from "../../../components/Card/StatsCard";
import packageIcon from "../../../assets/Icons/packageIcon.svg";
import calendarIcon from "../../../assets/Icons/calendarIcon.svg";
import timerIcon from "../../../assets/Icons/Timer.svg";
import backIcon from "../../../assets/Icons/backIcon.svg";
import Button from "../../../components/Button/Button";
import "./BookingDetailsPage.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  formatDate,
  formatSlotTime,
  formatDateTime,
} from "../../../Utils/helper";
import { open_api_with_user_token } from "../../../Utils/network";
import {
  addAlert,
  removeAlert,
  checkIfButtonShouldBeDisabled,
} from "../../../Utils/helper";
import CustomAlert from "../../../components/Alerts/CustomAlert";

const BookingDetailsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [bookingsDetailStatsData, setBookingDetailsStatsData] = useState([]);
  const [bookingDetailsApiData, setBookingDetailsApiData] = useState(null);
  const [customerResponseData, setCustomerResponseData] = useState(null);
  const [
    sessionPackagePurchaseDetailsData,
    setSessionPackagePurchaseDetailsData,
  ] = useState([]);
  const [bookingLink, setBookingLink] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // Track button state
  const { id } = useParams();
  const [alerts, setAlerts] = useState([]); // Holds multiple alerts
  const [showAlert, setShowAlert] = useState(false); // Controls visibility of alerts
  const [isSessionStarted, setIsSessionStarted] = useState(true);

  useEffect(() => {
    getBookingDetailsData();
  }, [id]);

  const getBookingDetailsData = async () => {
    try {
      const res = await open_api_with_user_token.get(
        `/session/booked_slot/purchase/details/${id}`
      );

      if (res.status === 200 || res.status === 201 || res.status === 204) {
        setBookingDetailsApiData(res.data.results[0]);
      } else {
        console.error("Unexpected response status:", res.status);
      }
    } catch (error) {
      console.error("Error fetching booking details data", error);
    }
  };

  useEffect(() => {
    if (bookingDetailsApiData) {
      setBookingDetailsStatsData([
        {
          icon: packageIcon,
          title: "Package Name",
          value: bookingDetailsApiData.session_purchase.package.title,
        },
        {
          icon: calendarIcon,
          title: "Scheduled date",
          value: formatDate(bookingDetailsApiData.slot.date),
        },
        {
          icon: timerIcon,
          title: "Scheduled Slot",
          value: formatSlotTime(
            bookingDetailsApiData.slot.date,
            bookingDetailsApiData.slot.time,
            bookingDetailsApiData.slot.duration
          ),
        },
      ]);

      if (bookingDetailsApiData.answers.length > 0) {
        const customerResponseDataFormated = mapApiDataToCustomerData(
          bookingDetailsApiData
        );
        setCustomerResponseData(customerResponseDataFormated);
      }

      const customerBookingDetailsFormated =
        mapApiDataToCustomerBookingDetailsData(bookingDetailsApiData);
      setSessionPackagePurchaseDetailsData(customerBookingDetailsFormated);
      setBookingLink(bookingDetailsApiData.jitsi_meeting_link);

      // Check if the current time has passed the end time of the slot
      checkIfButtonShouldBeDisabled(
        bookingDetailsApiData.slot.date,
        bookingDetailsApiData.slot.time,
        bookingDetailsApiData.slot.duration,
        setIsButtonDisabled,
        setIsSessionStarted
      );
    }
  }, [bookingDetailsApiData]);

  const mapApiDataToCustomerData = (bookingDetailsApiData) => {
    const customerName = `${bookingDetailsApiData.user.first_name} ${bookingDetailsApiData.user.last_name}`;
    const answers = bookingDetailsApiData?.answers;

    return answers.map((answer, index) => ({
      id: `${index + 1}`,
      customerName: customerName,
      question: answer.question_text,
      questionType:
        answer.question_type.toLowerCase() === "text"
          ? "Text"
          : "Multiple Choice",
      answer: answer.text_answer || answer.selected_options.join(", "),
    }));
  };

  const mapApiDataToCustomerBookingDetailsData = (bookingDetailsApiData) => {
    const customerName = `${bookingDetailsApiData.user.first_name} ${bookingDetailsApiData.user.last_name}`;
    const paymentBookingId =
      bookingDetailsApiData.payment?.transaction_id || "";
    const couponCode =
      bookingDetailsApiData.session_purchase?.coupon_code?.code || "";
    const createdDateTime = bookingDetailsApiData?.payment?.created;
    const { formattedDate, formattedTime } = formatDateTime(createdDateTime);
    const bookingDate = formattedDate;
    const bookingTime = formattedTime;
    const paymentStatus = bookingDetailsApiData.payment.payment_status_str;
    const paymentAmount = bookingDetailsApiData.session_purchase.total_pay;
    const currencySymbol =
      bookingDetailsApiData.session_purchase.currency === "INR" ? "₹" : "$";
    const formattedPaymentAmount = `${currencySymbol}${paymentAmount}`;

    return [
      {
        paymentID: paymentBookingId,
        customerName: customerName,
        purchaseDate: bookingDate,
        purchaseTime: bookingTime,
        couponUsed: couponCode,
        bookingTotal: formattedPaymentAmount,
        status: paymentStatus,
      },
    ];
  };

  const columnsCustomerResponse = [
    { header: "Customer Name", accessor: "customerName" },
    { header: "Question", accessor: "question" },
    { header: "Question Type", accessor: "questionType" },
    { header: "Answer", accessor: "answer" },
  ];

  const columnsCustomerBookingDetails = [
    { header: "Payment ID", accessor: "paymentID" },
    { header: "Customer Name", accessor: "customerName" },
    { header: "Purchase Date", accessor: "purchaseDate" },
    { header: "Purchase Time", accessor: "purchaseTime" },
    { header: "Coupon Used", accessor: "couponUsed" },
    { header: "Booking Total", accessor: "bookingTotal" },
    { header: "Status", accessor: "status" },
  ];

  const handleClick = () => {
    if (isSessionStarted) {
      handleAddAlert(
        "Warning",
        "Link will be enabled 30 mins before the session starts",
        "warning",
        true
      );
    } else if (!isButtonDisabled && bookingLink) {
      window.open(bookingLink, "_blank");
    } else {
      handleAddAlert("Warning", "This session has conclued", "warning", true);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // Fetch new data based on the newPage if needed
  };

  const handleBackClick = () => {
    navigate("/bookings/all");
  };

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
    setShowAlert(true); // Show alert when adding a new alert
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
    if (alerts.length <= 1) {
      setShowAlert(false); // Hide alert if there are no more alerts to show
    }
  };

  return (
    <div className="main-container">
      <div className="all-bookings-table-container">
        <div className="booking-details-header">
          <div className="booking-details-header-left">
            <img
              src={backIcon}
              alt="back icon"
              className="booking-details-back-icon"
              onClick={handleBackClick}
            />
            <h2>Booking details</h2>
          </div>
          <div className="booking-details-header-right">
            <Button
              text="Join Now"
              onClick={handleClick}
              backgroundColor={isButtonDisabled ? "#ccc" : "#FF67A1"}
            />
          </div>
        </div>
        <StatsCard data={bookingsDetailStatsData} />
        {customerResponseData && customerResponseData.length > 0 ? (
          <>
            <h2 className="booking-table-heading">Customer Responses</h2>
            <TableCard
              columns={columnsCustomerResponse}
              data={customerResponseData}
              totalEntries={customerResponseData.length}
              currentPage={1}
              totalPages={1}
              onPageChange={handlePageChange}
              isClickable={false}
            />
          </>
        ) : null}

        <h2 className="booking-table-heading">Booking details</h2>
        <TableCard
          columns={columnsCustomerBookingDetails}
          data={sessionPackagePurchaseDetailsData}
          totalEntries={sessionPackagePurchaseDetailsData.length}
          currentPage={1}
          totalPages={1}
          onPageChange={handlePageChange}
          isClickable={false}
        />
      </div>
      {showAlert &&
        alerts.map((alert) => (
          <CustomAlert
            key={alert.id}
            title={alert.title}
            message={alert.message}
            onClose={() => handleRemoveAlert(alert.id)}
            autoClose={alert.autoClose}
            type={alert.type}
          />
        ))}
    </div>
  );
};

export default BookingDetailsPage;
